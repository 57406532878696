import React, { useEffect } from 'react';
import SEO from "../common/SEO";
import Separator from "../elements/separator/Separator";
import SectionTitle from "../elements/sectionTitle/SectionTitle";
import {Link} from "react-router-dom";
import HeaderOne from '../common/header/HeaderOne';
import CounterUpFour from '../elements/counterup/CounterUpFour';
import ServiceOne from '../elements/service/ServiceOne';
import AboutFour from '../elements/about/AboutFour';
import AdvancePricingOne from '../elements/advancePricing/AdvancePricingOne';
import BrandOne from '../elements/brand/BrandOne';
import FooterTwo from '../common/footer/FooterTwo';
import Copyright from '../common/footer/Copyright';


const BannerData = [
    {
        image: "/images/bg/bg-image-20.jpg",
        title: "Cold Email <br /> Made Awesome",
        description: "We help our clients succeed by automating cold emailing with AI, <br/> reducing time and effort while enhancing engagement and efficiency."
    },
]


const WebAgency = () => {
    
    // useEffect(() => {
    //     const audio = new Audio('/audio/welcome_audio.mp3');
    //     audio.play().catch(error => console.error('Audio play failed:', error));
    // }, []);

    return (
        <>
            <SEO title="Emli Ai" />
            <main className="page-wrapper">
                {/* <HeaderOne btnStyle="btn-small" HeaderSTyle="header-not-transparent" /> */}


                {/* Start Slider Area  */}
                <div className="slider-area slider-style-8 height-750" style={{height: "100vh"}}>
                    {BannerData.map((data, index) => (
                        <div key={index} className="single-slide">
                            <div className="container">
                                <div className="row align-items-center">
                                    <div className="col-lg-12">
                                        <div className="inner text-center">
                                            <span className="subtitle theme-gradient">Hello! Emli Ai is here</span>
                                            <h1 className="title" dangerouslySetInnerHTML={{__html: data.title}}></h1>
                                            {/* <p className="description" dangerouslySetInnerHTML={{__html: data.description}}></p> */}
                                            <div className="button-group mt--30">
                                                <a className="btn-default btn-large round" href="">Explore Now</a>
                                                {/* <a className="btn-default btn-border btn-large round" href="#">Contact Us</a> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
                {/* End Slider Area  */}

                {/* <FooterTwo />
                <Copyright /> */}
            </main>
        </>
    )
}
export default WebAgency;